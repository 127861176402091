<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>해당 스페이스로 재생하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="$emit('closePlay')">아니오</p>
                <p id="color-text" @click="linkPlayer()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
// import router from '@/router';
import { leaveChannel } from '@/assets/js/webcast';
export default defineComponent({
  name: 'Play',
  props: {
    spaceType: String,
    spaceId: String,
    spaceNm: String,
    route: String
  },
  data () {
    return {
      cesplayerList: ['APLAYZ Bakery'],
      cespos: ['CES-POS']
    };
  },
  methods: {
    async linkPlayer () {
      // 브랜드음원 켜져있을 경우
      if (this.$store.state.isBrandMusic) {
        this.$BrandPlayer.onBrandPause();
        this.$store.commit('setClearBrandPlayerList', []);
        this.$store.commit('setBrandActiveIdx', -1);
        this.$store.commit('setIsBrandMusic', false);
        this.$store.commit('setIsBrandMusicPlay', false);
        this.$store.commit('setBrandMusicInfo', null);
      }

      this.$VideoPlayer.onPause();
      // channelId가 있을때
      if (this.$store.state.channelId !== '') await leaveChannel();

      VueCookies.set('spaceId', this.spaceId);
      this.$store.commit('setMusicInfoInterval', null);
      this.$store.commit('setSpaceId', null);
      this.$store.commit('setSpaceNm', this.spaceNm);
      this.$store.commit('setPlay', false);
      this.$store.state.player.isAddedJobInitFreePlayer = false;

      this.$emit('closePlay');
      this.$VideoPlayer.setVideoEle();
      this.$VideoPlayer.onMute(false);
      this.$VideoPlayer.setVolume(this.$store.state.volume);

      const email = this.$cookies.get('email');
      if (this.$store.getters['cesuserinfo/isCesUser'](email)) {
        if (this.route === 'cesplayer') {
          this.$cookies.set('cesSpaceId', this.spaceId);
          this.$router.push({ name: 'CesPlayer' }).catch(() => {});
        } else if (this.route === 'cespos') {
          this.$cookies.set('cesposSpaceId', this.spaceId);
          this.$router.push({ name: 'CesPosView' }).catch(() => {});
        }
      } else {
        this.$router
          .push({
            name: 'PlayerView',
            params: { spaceState: 'changeSpace', spaceType: this.spaceType, setIsLoading: true }
          })
          .catch(() => {});
        // router.push({ name: "PlayerWS", params: { spaceState: 'changeSpace' } }).catch(() => { });
      }
    }
  }
});
</script>
